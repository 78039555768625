<template>
  <div class="page">
    <div class="section padding20">
      <h2>{{ $t("setting.title") }}</h2>
      <div class="box">
        <p>{{ $t("nickname.name") }}</p>
        <div class="item">
          <input type="text" v-model="name" :placeholder="$t('nickname.tip')" max="10" />
          <div class="btn" @click="saveName()">{{ $t("nickname.save") }}</div>
        </div>
      </div>
      <div class="box">
        <p>{{ $t("email.email") }}</p>
        <div class="item">
          <input type="text" v-model="infos.email" disabled class="inp" />
          <div class="btn" @click="setEmailBox = true">{{$t('email.save')}}</div>
        </div>
      </div>
      <div class="box">
        <p>{{ $t("fund.loginPass") }}</p>
        <div class="modify" @click="setPwdBox = true">{{ $t("pass.save") }}</div>
      </div>
      <div class="box">
        <p>{{ $t("fund.fund") }}</p>
        <div class="modify" @click="setFundBox = true">
          {{ infos.has_paypass == 1 ? $t("pass.savefund") : $t("fund.savefund") }}
        </div>
      </div>
      <div class="box" v-if="infos.invitor_code">
        <p>{{ $t("share.invite") }}</p>
        <div class="item">
          <input type="text" v-model="infos.invitor_code" disabled />
        </div>
      </div>
      <!-- 设置密码弹框 -->
      <el-dialog :visible.sync="setPwdBox" :show-close="true" :center="true" :modal="false" :title="$t('pass.title')">
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.oldPass')" v-model="passForm.login_password"
              type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.newPass')" v-model="passForm.new_password"
              type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('pass.rePass')" v-model="passForm.confirm_password"
              type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="resetPwd()" :loading="isLoading">
              {{$t('messageBox.confirm')}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
      <!-- 设置资金弹框 -->
      <el-dialog :visible.sync="setFundBox" :show-close="true" :center="true" :modal="false" :title="$t('fund.title')">
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('fund.newPass')" v-model="fundForm.pay_password"
              type="password"></el-input>
          </el-form-item>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('fund.rePass')" v-model="fundForm.confirm_password"
              type="password"></el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('email.email')" v-model="fundForm.email">
            </el-input>
          </el-form-item> -->
          <el-form-item>
            <div class="inpDiv">
              <el-input autocomplete="off" :placeholder="$t('email.emailcode')" v-model="fundForm.code"></el-input>
              <div class="sendcode" @click="sendcode(1)">{{text}}</div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button style="width: 100%" type="primary" @click="refundPwd()" :loading="isLoading">
              {{$t('messageBox.confirm')}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- 设置邮箱弹框 -->
      <el-dialog :visible.sync="setEmailBox" :show-close="true" :center="true" :modal="false"
        :title="$t('email.title1')">
        <el-form>
          <el-form-item>
            <el-input autocomplete="off" :placeholder="$t('email.email')" v-model="emailForm.email">
            </el-input>
          </el-form-item>
          <el-form-item>
            <div class="inpDiv">
              <el-input autocomplete="off" :placeholder="$t('email.emailcode')" v-model="emailForm.code"></el-input>
              <div class="sendcode" @click="sendcode(0)">{{text}}</div>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button style="width:100%" type="primary" @click="resetEmail()" :loading="isLoading">
              {{$t('messageBox.newemail')}}</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>



<script>
import { mapState } from "vuex";
import { resetName, resetLoginPass, resetFundPass } from "../api/common/index.api";
import test from "../common/test";
export default {
  inject: ["reload"],
  data() {
    return {
      name: "",
      setPwdBox: false,
      isLoading: false,
      setFundBox: false,
      setEmailBox: false,
      isSend: false,
      text: this.$t('email.send'),
      passForm: {
        login_password: "",
        new_password: "",
        confirm_password: "",
      },
      fundForm: {
        pay_password: "",
        confirm_password: "",
        email: '',
        code: ''
      },
      emailForm: {
        email: '',
        code: ''
      },
      timer: null
    };
  },
  computed: {
    ...mapState({
      infos: (state) => state.infos,
    }),
    // text(){
    //   return this.$t('email.send')
    // }
  },
  created() {
    this.name = this.infos.nickname ? this.infos.nickname : "";
  },
  watch: {
    "infos.nickname"() {
      this.name = this.infos.nickname ? this.infos.nickname : "";
    },
    setEmailBox(newval, oldval) {
      if (newval) {
        clearInterval(this.timer)
        this.text = this.$t('email.send')
        this.isSend = false
        this.emailForm = {
          email: '',
          code: ''
        }
      }
    },
    setFundBox(newval, oldval) {
      if (newval) {
        clearInterval(this.timer)
        this.text = this.$t('email.send')
        this.isSend = false
        this.fundForm = {
          pay_password: "",
          confirm_password: "",
          email: '',
          code: ''
        }
      }
    },
    setPwdBox(newval, oldval) {
      if (newval) {
        this.isLoading = false
      }
    }
  },
  methods: {
    saveName() {
      if (!this.name) return this.$message(this.$t('nickname.p_nickname'));
      if (this.name.length > 10) return this.$message(this.$t('nickname.p_nicknamemax'));
      if (this.name == this.infos.name) return this.$message(this.$t('nickname.unchanged'));
      resetName(
        { nickname: this.name },
        { Serverid: this.$store.state.serverid }
      ).then((res) => {
        this.$store.dispatch("Get_infos");
        this.$message({
          message: this.$t('nickname.ok'),
          type: "success",
        });
      });
    },
    resetPwd() {
      if (!this.passForm.login_password) return this.$message(this.$t('code.pwd_code'));
      if (!this.passForm.new_password) return this.$message(this.$t('code.pwd_newcode'));
      if (this.passForm.confirm_password != this.passForm.new_password)
        return this.$message(this.$t('pass.err_rePass'));
      this.isLoading = true;
      resetLoginPass(this.passForm, {
        Serverid: this.$store.state.serverid,
      }).then((res) => {
        this.passForm = {
          login_password: "",
          new_password: "",
          confirm_password: "",
        };
        this.$message({
          message: this.$t('email.modisuc'),
          type: "success",
        });
        this.$storage.remove("accountRemember");
        this.setPwdBox = false;

        this.isLoading = false;
      }).catch(err => {
        this.isLoading = false;
      })
    },
    refundPwd() {
      if (!this.fundForm.pay_password) return this.$message(this.$t('fund.p_newpass'));
      if (this.fundForm.confirm_password != this.fundForm.pay_password)
        return this.$message(this.$t('pass.err_rePass'));
      console.log(this.fundForm.email);
      if (!this.fundForm.code) {
        return this.$message(this.$t('email.p_emailcode'));
      }
      this.isLoading = true;
      resetFundPass(this.fundForm, {
        Serverid: this.$store.state.serverid,
      }).then((res) => {
        this.$message({
          message: this.$t('email.modisuc'),
          type: "success",
        });
        this.setFundBox = false;
        this.isLoading = false;
        this.reload()
      }).catch(err => {
        this.isLoading = false;
      })
    },
    resetEmail() {
      if (!test.email(this.emailForm.email)) {
        return this.$message(this.$t('email.err_email'));
      }
      if (!this.emailForm.code) {
        return this.$message(this.$t('email.p_emailcode'));
      }
      this.isLoading = true
      this.$axios.post("/api/user/set_email", this.emailForm,
        { Serverid: this.$store.state.serverid }).then(res => {
          this.emailForm = {
            email: '',
            code: ''
          }
          this.setEmailBox = false
          this.$message(this.$t('email.modiEmailsuc'))
          this.isLoading = false
          this.$store.dispatch("Get_infos")
        }).catch(err => {
          this.isLoading = false
        })
    },
    sendcode(type) {
      if (type == 0) {
        if (!test.email(this.emailForm.email)) {
          return this.$message(this.$t('email.err_email'));
        }
      }
      if (this.isSend) return
      this.isSend = true

      this.$axios.post(this.$api.sendCode, { email: type == 0 ? this.emailForm.email : this.infos.email },
        { Serverid: this.$store.state.serverid }).then(res => {

          this.text = 60
          this.timer = setInterval(() => {
            this.text--;
            if (this.text == 0) {
              this.text = this.$t('email.send')
              this.isSend = false
              clearInterval(this.timer)
            }
          }, 1000);
        })
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  width: 100%;
  background: rgba(41, 43, 57, 0.5);
  border: 1px solid #4c5158;
  border-radius: 4px;

  h2 {
    font-size: 28px;
    font-weight: 700;
  }
  h4 {
    font-size: 24px;
    font-weight: bold;
    color: #596076;
    padding-top: 25px;
  }
  .box {
    .modify {
      width: 240px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      background: #317F6E;
      border-radius: 4px;
      cursor: pointer;
    }
    > p {
      font-size: 14px;
      font-weight: 500;
      color: #ffffff;
      line-height: 20px;
      margin-top: 20px;
      margin-bottom: 5px;
    }
    > span {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      line-height: 35px;
      // padding: 0 14px;
    }
    .item {
      display: flex;
      align-items: center;
      input {
        width: 240px;
        max-width: 300px;
        height: 36px;
        font-size: 14px;
        font-weight: 500;
        color: #ffffff;
        background: #0f1016;
        border: 1px solid #4c5158;
        border-radius: 4px;
        padding-left: 10px;
      }
      .inp {
        background: #333749;
      }
      .btn {
        min-width: 100px;
        padding: 0 5px;
        height: 36px;
        line-height: 36px;
        border: 1px solid #37B497;
        border-radius: 4px;
        font-size: 13px;
        font-weight: 700;
        text-align: center;
        margin-left: 20px;
        color: #37B497;
        cursor: pointer;
      }
    }
    .email {
      // height: 35px;
      color: #7e7e7e;
      background-color: #333746;
    }

    .pass {
      height: 20px;
      vertical-align: middle;
      margin-right: 8px;
    }
  }

  .inpDiv {
    position: relative;
    .sendcode {
      width: 60px;
      height: 38px;
      background: #37B497;
      position: absolute;
      right: 0;
      top: 2px;
      text-align: center;
      color: #fff;
      cursor: pointer;
    }
  }
}
</style>